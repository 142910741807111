@font-face {
  font-family: 'Chinook';
  src: local('Chinook'),
    url('./assets/fonts/Chinook-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Chinook Italic';
  src: local('Chinook Italic'),
    url('./assets/fonts/Chinook-CustomItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Gemos';
  src: local('Gemos'), url('./assets/fonts/gGemos.ttf') format('truetype');
}

@font-face {
  font-family: 'Schools out';
  src: local('Schools out'),
    url("./assets/fonts/HalfTerm\,School'sOut-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Coolvetica';
  src: local('Coolvetica'),
    url('./assets/fonts/coolvetica\ rg.otf') format('opentype');
}

@font-face {
  font-family: 'Butler';
  src: local('Butler'),
    url('./assets/fonts/Butler_Regular_Stencil.otf') format('opentype');
}

@font-face {
  font-family: 'Butler bold';
  src: local('Butler bold'),
    url('./assets/fonts/Butler_Bold_Stencil.otf') format('opentype');
}

.tabWrapper {
  width: 100%;
  background-color: white;
  color: black;
  /* border-radius: 10px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0.3125rem;
  height: 100vh;
  font-family: 'Chinook';
}

.tabHeader {
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem;
  align-items: center;
  justify-content: space-evenly;
}

.tabItem {
  position: relative;

  p {
    padding: 5px 10px;
    display: block;
    color: #0d4603;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 700;
  }

  &.selected {
    p {
      color: #5b0c58;
    }
  }
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #690c66;
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 10px;
  color: black;
  height: 100%;
  background-color: white;
  overflow: auto;
  font-family: Coolvetica;
  font-size: 18px;
  width: 100%;
  padding-bottom: 3rem;

  h3 {
    color: #544040;
    font-family: 'Chinook';
  }

  h4 {
    font-family: Chinook Italic;
    text-align: center;
  }

  li {
    color: black;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.popup-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #e58e98;
  padding: 1rem 2rem;
  border-radius: 5px;
  transition: 0.3s;
  margin-top: 3rem;
}

.popup-button:hover {
  background-color: #e36271;
}

.join-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel {
  position: relative;
  max-width: 32%;
  overflow: hidden;
}
.carousel__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
}
.carousel__btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel__btn--prev {
  left: 0;
}
.carousel__btn--next {
  right: 0;
}
.carousel__img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.carousel__img:hover {
  transform: scale(1.05);
}

.carousel-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
footer {
  width: 100%;
  position: fixed;
  bottom: 1rem;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: black;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      margin: 0;
    }
  }
}
.director-gender {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.pieChart-main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 2rem;
}

.pieChart-container {
  width: 80%;
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
}
.chart-container {
  width: 350px !important;
}
.pieChart_title_and_text {
  display: flex;
  flex-direction: column;
}

.chevron-title {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 8px;
  transition: 0.5s;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
